import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import _ from 'lodash';
import ViewARButton from './view-ar';

const ProductCardDiv = styled.div`
font-family: "Lato";
max-width: 400px;
margin: auto;
padding: 15px;
border: 1px solid #ebebeb;
text-align: center;
  img {
    width:100%;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    margin: 5px;
  }
  h4 {
    font-size: 11px;
    font-weight: 400;
    margin:5px;
  }
`;

const ProductCard = (props) => {
  const {
    nameLine1, nameLine2, image, usdz, glb, buttonAR, messageNoAr,
  } = props;
  const graphqlResult = useStaticQuery(graphql`
  {
    allFile(filter: {extension: {in: ["jpeg", "jpg", "png"]}}) {
      nodes {
        name
        publicURL
        childImageSharp {
          id
          gatsbyImageData(
            width: 400, 
            placeholder: BLURRED
          )
        }
      }
    }
  }
  `);
  const imageFile = _.find(graphqlResult.allFile.nodes, { name: image });
  const imageGatsby = getImage(imageFile);
  const showARbutton = buttonAR === 'iOS' || buttonAR === 'Android';

  return (
    <ProductCardDiv>
      <GatsbyImage
        image={imageGatsby}
        alt={nameLine1}
        placeholder="blurred"
      />
      <h3>{nameLine1}</h3>
      <h4>{nameLine2 || '\u00A0'}</h4>
      {showARbutton
        ? <ViewARButton usdz={usdz} glb={glb} buttonAR={buttonAR} />
        : <p>{messageNoAr}</p>}
    </ProductCardDiv>
  );
};

ProductCard.propTypes = {
  nameLine1: PropTypes.string.isRequired,
  nameLine2: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  usdz: PropTypes.string.isRequired,
  glb: PropTypes.string.isRequired,
  buttonAR: PropTypes.string.isRequired,
  messageNoAr: PropTypes.string.isRequired,
};

export default ProductCard;
