import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const GridWrapper = styled.section`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
`;

const Grid = ({ columns, children, style }) => (
  <GridWrapper columns={columns} style={style}>
    {children}
  </GridWrapper>
);

Grid.propTypes = {
  columns: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  style: PropTypes.string.isRequired,
};

export default Grid;
