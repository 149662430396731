import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImgViewAr from './img-view-ar';

// styles

const ViewArDiv = styled.div`
float:left  
width: "49%";  
  a {
    display: block;
    background-color: #ebebeb;
    color: white;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 0px;
    line-height: 1;
  }
  a:hover {
    background-color: #3b8cd6;
    color: #fff;
  }
`;

export default function ViewARButton(props) {
  const { usdz, glb, buttonAR } = props;

  const usdzFilePublicUrl = `https://storage.googleapis.com/wells-snapscene.appspot.com/models/${usdz}.usdz`;
  const glbFilePublicUrl = `https://storage.googleapis.com/wells-snapscene.appspot.com/models/${glb}.glb`;
  // const siteUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.1.233:8000' : graphqlResult.site.siteMetadata.siteUrl;
  // const androidHref = `intent://arvr.google.com/scene-viewer/1.0?file=${siteUrl}${glbFilePublicUrl}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
  const androidHref = `intent://arvr.google.com/scene-viewer/1.0?file=${glbFilePublicUrl}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;

  const showIOS = buttonAR === 'iOS';
  const href = showIOS ? usdzFilePublicUrl : androidHref;

  return (
    <ViewArDiv>
      <a rel="ar" href={href}>
        <ImgViewAr />
      </a>
    </ViewArDiv>
  );
}

ViewARButton.propTypes = {
  usdz: PropTypes.string.isRequired,
  glb: PropTypes.string.isRequired,
  buttonAR: PropTypes.string.isRequired,
};
