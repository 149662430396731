import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function ImageLogo() {
  return (
    <StaticImage
      src="../images/singapore/wells_final logo_final_19April.png"
      alt="Wells Singapore"
      placeholder="blurred"
      layout="Constrained"
      width={360}
    />
  );
}
