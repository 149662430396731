import * as React from 'react';
// import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

const ImgViewAr = () => {
  const graphqlResult = useStaticQuery(graphql`
  {
    file(name: {eq: "snapscene-view-ar"}) {
      id
      name
      publicURL
    }
  }
  `);
  const { name, publicURL } = graphqlResult.file;

  return (
    <img
      src={publicURL}
      alt={name}
    />
  );
};

export default ImgViewAr;
