import * as React from 'react';
import {
  isMobile, isAndroid, isIOS, isChrome, isSafari,
} from 'react-device-detect';
// Components
import ImageLogo from '../components/image-logo';
import ProductCard from '../components/product-card';
import Grid from '../components/grid';
//  import ArkitCheck from '../components/arkit-check';
// Data
import singapore from '../../content/wells-singapore.yaml';
// Font
import '@fontsource/lato';

// styles
const pageStyles = {
  color: '#232129',
  padding: 32,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const deviceMessageStyle = {
  padding: '20px',
  backgroundColor: 'gray',
  color: 'black',
};

const IndexPage = () => {
  let deviceMessage = 'Device & browser not detected';
  let displayARbutton = 'no';
  if (!isMobile) {
    deviceMessage = 'AR only available on mobile & Tablet';
  } else {
    deviceMessage = 'AR only available on iOS & Adnroid devices';
    if (isIOS) {
      if (isSafari) {
        deviceMessage = 'Yes compatible on iOS and Safari';
        displayARbutton = 'iOS';
      } else {
        deviceMessage = 'Please use Safari on iOS device';
      }
    }
    if (isAndroid) {
      if (isChrome) {
        deviceMessage = 'Yes compatible on Android and Chrome';
        displayARbutton = 'Android';
      } else {
        deviceMessage = 'Please use Chrome on Android device';
      }
    }
  }

  return (
    <main style={pageStyles}>

      <title>{singapore.Title}</title>
      <section>
        {displayARbutton === 'no'
          ? (
            <div style={deviceMessageStyle}>
              <strong>Not compatible!</strong>
              {deviceMessage}
            </div>
          )
          : ''}
      </section>
      <section>
        <ImageLogo />
      </section>
      <section>
        <Grid columns={2} style={{ gridColumnGap: '16px', gridRowGap: '16px' }}>
          {singapore.products.map((product) => (
            <ProductCard
              nameLine1={product.nameLine1}
              nameLine2={product.nameLine2}
              image={product.image}
              usdz={product.usdz}
              glb={product.glb}
              buttonAR={displayARbutton}
              messageNoAr={deviceMessage}
            />
          ))}
        </Grid>
      </section>
    </main>
  );
};

export default IndexPage;
